<template>
  <div class="p-1">
    <div class="flex justify-between flex-wrap items-center mb-2">
      <h3 class="text-2xl">Inscripciones</h3>
      <a-button :to="{ name: 'create_preinscription' }" tag="a" v-if="!user.isStudent && !user.isLowAdmin" class="w-full md:w-auto">
        Crear inscripción
      </a-button>
    </div>

    <a-table
      :columns="columns"
      :source="pagination.data"
      :loading="loading"
      :pagination="pagination"
      @paginate="search({ page: $event })"
      toolbar-column-filter
      @toolbar(refresh)="search({ page: 1 })">

      <template #toolbar(search)>
        <a-input
            is-search
            placeholder="Buscar..."
            @search="search({ search: $event, page: 1 })" />
      </template>

      <template #td(actions)="{ item }">
        <a-dropdown>
          <template #menu>
            <a-dropdown-item :to="{ name: 'preinscription', params: { preinscription_id: item.id } }">
              Ver
            </a-dropdown-item>
            <a-dropdown-item :to="{ name: 'create_preinscription', query: { update: item.id } }" v-if="user.isAdmin">
              Actualizar
            </a-dropdown-item>
            <a-dropdown-item @click="approvePreinscription(item.id)" v-if="user.isAdmin">
              Aprobar
            </a-dropdown-item>
            <a-dropdown-item @click="deletePreinscription(item)" v-if="user.isAdmin">
              Eliminar
            </a-dropdown-item>
          </template>
        </a-dropdown>
      </template>
    </a-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Vue from 'vue'

export default {
  data: () => ({
    query: {
      limit: 10,
      with: 'period,specialization',
      not_confirmed: true,
      search: '',
      sort_by: 'last_name'
    },
    timeout: null
  }),
  computed: {
    ...mapState({
      user: state => state.session.user,
      pagination: state => state.preinscriptions.pagination,
      loading: state => state.preinscriptions.loading
    }),
    columns() {
      return [
        { title: 'Apellido', key: 'last_name', alignment: 'center', visible: true, display: true },
        { title: 'Nombre', key: 'first_name', visible: true, display: true },
        { title: 'Email', key: 'email', visible: false, display: true },
        { title: 'Cédula', key: 'id_document', visible: true, display: true },
        { title: 'Género', key: 'gender', alignment: 'right', mutate: ({ key }) => key === 'male' ? 'Masculino' : 'Femenino', visible: false, display: true },
        { title: 'Período', key: 'period', alignment: 'right', mutate: ({ key }) => `${key.year}-${key.number}`, visible: true, display: true },
        { title: 'Especialidad', key: 'specialization.title', alignment: 'right', visible: false, display: true },
        { title: 'Turno', key: 'schedule_shift', alignment: 'right', mutate: ({ key }) => this.getSheduleShift(key), visible: true, display: true },
        { title: 'Dirección', key: 'address', alignment: 'right', visible: false, display: true },
        { title: 'Lugar de nacimiento', key: 'birth_place', alignment: 'right', visible: false, display: true },
        { title: 'Fecha de nacimiento', key: 'birth_date', alignment: 'right', visible: false, display: true },
        { title: 'Teléfono', key: 'phone', alignment: 'right', visible: false, display: true },
        { title: 'Teléfono celular', key: 'mobile_phone', alignment: 'right', visible: false, display: true },
        { title: 'Fax', key: 'fax', alignment: 'right', visible: false, display: true },
        { title: 'Fecha de pago', key: 'payment_date', alignment: 'right', visible: true, display: true },
        { title: 'Total pagado', key: 'payment_amount', alignment: 'right', mutate: ({ key }) => `${'$' + parseFloat((key / 100)).toFixed(2)}` ,visible: true, display: true },
        { title: 'Tipo de pago', key: 'payment_type', alignment: 'right', visible: false, display: true },
        { title: 'Factura', key: 'payment_receipt_number', alignment: 'right', visible: true, display: true },
        { title: 'Fondo educativo', key: 'educational_background', alignment: 'right', visible: false, display: true },
        { title: 'Nombre de instituto educativo', key: 'educational_institution_name', alignment: 'right', visible: false, display: true },
        { title: 'Fecha de graduación', key: 'educational_graduation_date', alignment: 'right', visible: false, display: true },
        { title: 'Actividad educativa actual', key: 'educational_current_activity', alignment: 'right', visible: false, display: true },
        { title: 'Email del representante', key: 'representative_email', alignment: 'right', visible: false, display: true },
        { title: 'Nombre del representante', key: 'representative_name', alignment: 'right', visible: false, display: true },
        { title: 'Profesión representativa', key: 'representative_profession', alignment: 'right', visible: false, display: true },
        { title: 'Dirección representativa', key: 'representative_address', alignment: 'right', visible: false, display: true },
        { title: 'Fecha de confirmación', key: 'confirmed_at', alignment: 'right', visible: false, display: true },
        { title: 'Acciones', slot: 'actions', alignment: 'right', visible: true, columnFilterable: false, display: this.user.isAdminGroup },
      ]
    }
  },
  methods: {
    ...mapActions({
      fetchAll: 'preinscriptions/fetchAll',
      confirm: 'preinscriptions/confirm',
    }),
    deletePreinscription(item) {
      if (confirm(`¿Desea eliminar esta preinscripción?`)) {
        this.$repository.preinscriptions
          .delete(item.id)
          .then(() => (this.$router.go(0)))
          .catch((err) => console.log(err))
      }
    },
    approvePreinscription(item){
      Vue.prototype.$confirm({
        title: '¿Aprobar?',
        message: `Esta acción require confirmación`,
        button: {
          no: 'No',
          yes: 'Si'
        },
        callback: confirm => {
          if (confirm) {
            this.$repository.preinscriptions
              .confirm(item)
              .then(() => {
                Vue.prototype.$message.success({
                  message: `Se ha aprobado con éxito`,
                })
                this.fetchAll()
              })
          }
        }
      })
    },
    getSheduleShift(shift) {
      if (shift === 'morning') return 'Mañana';
      if (shift === 'afternoon') return 'Tarde';
      return 'Mixto'
    },
    search(query) {
      this.fetchAll({ ...this.query, ...query })
    }
  },
  mounted() {
    this.search({ ...this.query, page: this.$route.query.page || 1 })
  }
}
</script>
